import React from 'react'
import classnames from 'classnames'
import arrow from '../images/cta-arrow.svg'
import whiteArrow from '../images/cta-arrow-white.svg'

export default function SliderArrowButton({
  slideCount,
  currentSlide,
  className,
  isPrev,
  isWhiteArrow,
  imgClassName,
  ...props
}) {
  return (
    <button
      {...props}
      className={classnames(
        'hidden lg:block absolute carousel-button-middle p-2 lg:p-4 z-50',
        {
          'carousel-button-middle--prev': isPrev,
        }
      )}
    >
      <img
        src={isWhiteArrow ? whiteArrow : arrow}
        alt=""
        className={classnames('block h-6', imgClassName)}
        style={{ transform: isPrev ? 'rotate(180deg)' : 'none' }}
      />
    </button>
  )
}
