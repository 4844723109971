import React from 'react'
import $ from 'jquery'
import classnames from 'classnames'
import SliderArrowButton from './SliderArrowButton'

const slickSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  fade: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: true,
}

export default class HomeHeroSlider extends React.Component {
  time = 5
  percentTime = 0
  isPause = false
  isActive = false
  tick = null

  progressBarRef = null
  sliderRef

  state = {
    current: 0,
    startedFirst: false,
  }

  afterChange = index => {
    this.setState({ current: index })
  }

  startProgressbar = () => {
    this.resetProgressbar()
    this.percentTime = 0
    this.isPause = false
    this.isActive = true
    window.requestAnimationFrame(this.stepAnimation)
  }

  resetProgressbar = () => {
    document.getElementById('slider-progressbar').style.width = 0 + '%'
    this.animationStart = undefined
    this.isPause = true
  }

  stepAnimation = timestamp => {
    if (!this.isActive) {
      return
    }

    if (!this.animationStart) {
      this.animationStart = timestamp
    }

    this.percentTime = ((timestamp - this.animationStart) / 8000) * 100
    document.getElementById('slider-progressbar').style.width =
      (this.percentTime > 100 ? 100 : this.percentTime) + '%'
    if (!this.isPause) {
      window.requestAnimationFrame(this.stepAnimation)
    }
    if (this.percentTime >= 100) {
      $('.home-hero-slider').slick('slickNext')
      this.startProgressbar()
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      import('slick-carousel').then(() => {
        const sliderElement = $('.home-hero-slider')
        this.setState(() => ({ startedFirst: true }))
        sliderElement.slick(slickSettings)
        sliderElement.on('beforeChange', (event, slick, oldIndex, index) =>
          this.afterChange(index)
        )
        this.startProgressbar()
      })
    }
  }

  componentWillUnmount() {
    this.isActive = false
  }

  onPrevClick = () => {
    this.resetProgressbar()
    $('.home-hero-slider').slick('slickNext')
    this.startProgressbar()
  }

  onNextClick = () => {
    this.resetProgressbar()
    $('.home-hero-slider').slick('slickNext')
    this.startProgressbar()
  }

  render() {
    const slides = this.props.slides.map(slide => slide.primary)

    return (
      <div className="relative">
        <SliderArrowButton
          isWhiteArrow
          imgClassName="h-10"
          isPrev
          onClick={this.onPrevClick}
        />
        <SliderArrowButton
          isWhiteArrow
          imgClassName="h-10"
          onClick={this.onNextClick}
        />
        <div className="home-hero-slider">
          {slides.map((slide, index) => {
            let href = '/'

            switch (slide.link._linkType) {
              case 'Link.web':
                href = slide.link.url
                break
              case 'Link.document':
                switch (slide.link._meta.type) {
                  case 'article':
                    href = `/insight/${slide.link._meta.uid}`
                    break
                  case 'podcast':
                    href = `/podcast/${slide.link._meta.uid}`
                    break
                  default:
                    href = ''
                }
                break
              default:
                href = ''
            }

            return (
              <Slide
                index={index}
                key={index}
                current={this.state.current}
                backgroundImage={slide.background_image.url}
                hideNotFirst={!this.state.startedFirst}
              >
                <div className="lg:max-w-full text-center">
                  <h2
                    className={classnames(
                      'text-xl lg:text-2xl font-serif font-normal text-white my-4 lg:my-0',
                      { 'measure-narrow': index < 2 }
                    )}
                  >
                    <a href={href} className="no-underline text-white">
                      {slide.text}
                    </a>
                  </h2>
                  <a
                    href={href}
                    className="inline-flex font-sans rounded-xl mt-8 lg:mt-16 text-md lg:text-lg hover:bg-white hover:text-blue-dianne text-white border border-white px-8 py-4 items-center no-underline"
                  >
                    <span className="cta-ie11-fix">
                      {slide.link_label || 'Read More'}
                    </span>{' '}
                  </a>
                </div>
              </Slide>
            )
          })}
        </div>
        <div
          id="slider-progressbar"
          className={classnames(
            'absolute pin-l pin-b h-1',
            `bg-${this.props.slides[this.state.current].primary.focus_area._meta.uid}`
          )}
        ></div>
      </div>
    )
  }
}

export function Slide({
  children,
  style,
  index,
  current,
  backgroundImage,
  hideNotFirst,
  ...props
}) {
  return (
    <div
      className={classnames(
        'w-full px-8 font-sans items-center home-hero-slide',
        { 'home-hero-slide--loading': hideNotFirst && index > 0 }
      )}
      style={{
        ...style,
        outline: 'none',
        background: `url("${backgroundImage}") no-repeat center center`,
        backgroundSize: 'cover',
        height: '70vh',
        maxHeight: 1000,
        display: 'flex',
      }}
      {...props}
    >
      <div className="relative min-h-70 lg:px-8 py-8 lg:py-0 mx-auto flex items-center justify-center flex-grow lg:border-l lg:border-white lg:mx-85px">
        {children}
      </div>
    </div>
  )
}
