import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'
import { Link as ScrollLink } from 'react-scroll'

import Layout from '../components/layout'
import SEO from '../components/seo'

import HomePodcasts from '../components/HomePodcasts'
import HomeHeroSlider from '../components/HomeHeroSlider'
import ArticlesColumn from '../components/ArticlesColumn'
import TrendsColumn from '../components/TrendsColumn'

import arrow from '../images/cta-arrow.svg'

import HomeFocusAreas from '../components/HomeFocusAreas'

import dotsTop from '../images/dots-top.svg'

import latestArticleImage from '../images/related-3.jpg'

const EXPLORER_ARTICLES = [
  {
    title: 'Recommended Books',
    image: 'explorerImage1',
    link: '/recommended-books',
  },
  {
    title: 'Reports and Infographics',
    image: 'explorerImage3',
    link: '/infographics-reports',
  },
  {
    title: 'Longevity\n Trends',
    image: 'explorerImage2',
    link: '/trends',
  },
]

const query = graphql`
  query {
    initiatives1Image: file(relativePath: { eq: "initiatives-1-new.jpg" }) {
      childImageSharp {
        fixed(width: 293) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    initiativesMobileImage: file(
      relativePath: { eq: "initiatives-1-new.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 414) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    initiatives2Image: file(relativePath: { eq: "initiatives-2-new.jpg" }) {
      childImageSharp {
        fixed(width: 293) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    initiatives3Image: file(relativePath: { eq: "initiatives-3-new.jpg" }) {
      childImageSharp {
        fixed(width: 293) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    explorerImage1: file(relativePath: { eq: "explorer-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    explorerImage1: file(relativePath: { eq: "explorer-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    explorerImage2: file(relativePath: { eq: "explorer-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    explorerImage3: file(relativePath: { eq: "explorer-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    networkImage: file(relativePath: { eq: "network-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    prismic {
      allHomepages {
        edges {
          node {
            title
            hero_text
            hero_cta_label
            column_1_header
            column_1_title
            column_1_text
            column_1_link {
              ...Link
            }
            column_2_header
            column_2_title
            column_2_text
            column_2_link {
              ...Link
            }
            column_3_header
            column_3_title
            column_3_text
            column_3_link {
              ...Link
            }
            initiatives_header
            initiatives_description
            initiatives_cta_label

            network_header
            network_description
            network_cta_label
            network_links {
              link_label
            }
            _linkType

            latestArticle1: article_1 {
              ... on PRISMIC_Article {
                _meta {
                  uid
                  firstPublicationDate
                }
                title
                image
                excerpt
                focusarea {
                  ...FocusArea
                }
                body {
                  ... on PRISMIC_ArticleBodyText {
                    primary {
                      text
                    }
                  }
                }
              }
            }
            latestArticle2: article_2 {
              ... on PRISMIC_Article {
                _meta {
                  uid
                  firstPublicationDate
                }
                title
                image
                excerpt
                focusarea {
                  ...FocusArea
                }
                body {
                  ... on PRISMIC_ArticleBodyText {
                    primary {
                      text
                    }
                  }
                }
              }
            }
            latestArticle3: article_3 {
              ... on PRISMIC_Article {
                _meta {
                  uid
                  firstPublicationDate
                }
                title
                image
                excerpt
                focusarea {
                  ...FocusArea
                }
                body {
                  ... on PRISMIC_ArticleBodyText {
                    primary {
                      text
                    }
                  }
                }
              }
            }

            podcasts {
              podcast {
                ... on PRISMIC_Podcast {
                  _meta {
                    uid
                    firstPublicationDate
                  }
                  title
                  focusarea {
                    ...FocusArea
                  }
                  expert {
                    ... on PRISMIC_Expert {
                      _meta {
                        uid
                      }
                      name
                      title
                      photo
                    }
                  }
                }
              }
            }

            body {
              ... on PRISMIC_HomepageBodySlide {
                primary {
                  focus_area {
                    ... on PRISMIC_Focus_area {
                      name
                      _meta {
                        uid
                      }
                    }
                  }
                  text
                  link_label
                  link {
                    _linkType
                    ... on PRISMIC__Document {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  background_image
                }
              }
            }

            article {
              ... on PRISMIC_Article {
                _meta {
                  uid
                  firstPublicationDate
                }
                title
                image
                excerpt
                focusarea {
                  ...FocusArea
                }
                body {
                  ... on PRISMIC_ArticleBodyText {
                    primary {
                      text
                    }
                  }
                }
              }
            }
            editors_choice_title
            editors_choice_image
            editors_choice_excerpt
            editors_choice_read_more_label
          }
        }
      }

      recommendedBooksPage: recommended_books_page(
        uid: "recommended-books"
        lang: "en-us"
      ) {
        body {
          ... on PRISMIC_Recommended_books_pageBodyBook {
            primary {
              book_title
              is_featured
              book_author
              focus_area {
                ... on PRISMIC_Focus_area {
                  name
                  _meta {
                    uid
                  }
                }
              }
              book_description
              book_cover
            }
          }
        }
      }

      latestTrends: allTrends(
        sortBy: meta_firstPublicationDate_DESC
        first: 3
      ) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
            }
            title
            focusArea: focusarea {
              ... on PRISMIC_Focus_area {
                _meta {
                  uid
                }
              }
            }
            description: excerpt
            link {
              ... on PRISMIC__ExternalLink {
                url
                _linkType
              }
            }
            linkLabel: link_label
            thumbnail
            linkLogo: link_logo
          }
        }
      }
    }

    trendsAllFiles: allFile(filter: { name: { glob: "trend__logo--*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed: fixed(quality: 100, grayscale: true, height: 24) {
              src
            }
          }
        }
      }
    }
  }

  fragment Link on PRISMIC_Focus_area_page {
    _meta {
      uid
    }
  }

  fragment FocusArea on PRISMIC_Focus_area {
    _meta {
      uid
    }
    name
  }
`

class IndexPage extends React.PureComponent {
  render() {
    return (
      <StaticQuery
        query={query}
        render={withPreview(
          ({
            prismic: {
              allHomepages: homepageData,
              recommendedBooksPage,
              latestTrends: latestTrendsCollection,
            },
            trendsAllFiles,
            ...queryData
          }) => {
            const column1 = {
              header: homepageData.edges[0].node.column_1_header,
              title: homepageData.edges[0].node.column_1_title,
              text: homepageData.edges[0].node.column_1_text,
            }
            const column2 = {
              header: homepageData.edges[0].node.column_2_header,
              title: homepageData.edges[0].node.column_2_title,
              text: homepageData.edges[0].node.column_2_text,
            }
            const column3 = {
              header: homepageData.edges[0].node.column_3_header,
              title: homepageData.edges[0].node.column_3_title,
              text: homepageData.edges[0].node.column_3_text,
            }

            const {
              latestArticle1,
              latestArticle2,
              latestArticle3,
              podcasts: latestPodcastsRaw,
              body: slides,
              article: editorsChoice,
            } = homepageData.edges[0].node

            const latestArticlesRaw = [
              latestArticle1,
              latestArticle2,
              latestArticle3,
            ]

            const calculatedExcerpts = [
              ...latestArticlesRaw,
              editorsChoice,
            ].reduce((acc, article) => {
              const firstParagraph =
                article.body && article.body[0].primary
                  ? article.body[0].primary.text
                  : null

              if (!firstParagraph) {
                return { ...acc, [article._meta.uid]: '' }
              }

              const full = article.body ? RichText.asText(firstParagraph) : ''

              return {
                ...acc,
                [article._meta.uid]:
                  full.slice(0, 160) +
                  (full.slice(0, 160).substr(-1) === '.' ? '' : '...'),
              }
            }, {})

            const latestArticles = latestArticlesRaw.map(article => ({
              uid: article._meta.uid,
              title: article.title,
              description:
                article.excerpt || calculatedExcerpts[article._meta.uid],
              image: article.image ? article.image.url : latestArticleImage,
              createdAt: article._meta.firstPublicationDate,
              category: article.focusarea,
            }))

            const latestPodcasts = latestPodcastsRaw
              .map(({ podcast }) => podcast)
              .filter(podcast => Boolean(podcast))

            const books = recommendedBooksPage.body.map(body => body.primary)
            const featuredBook = books.find(book => book.is_featured === 'Yes')

            const latestTrendsRaw = latestTrendsCollection.edges

            const latestTrends = latestTrendsRaw.map(
              ({
                node: {
                  title,
                  description,
                  link,
                  focusArea,
                  linkLabel,
                  linkLogo,
                  ...node
                },
              }) => {
                const whereToSlice =
                  description.slice(0, 160).substr(-1) === ' ' ? 159 : 160
                const excerpt =
                  description.length > 160
                    ? description.slice(0, whereToSlice) +
                      (description.slice(0, whereToSlice).substr(-1) === '.'
                        ? ''
                        : '...')
                    : description

                return {
                  uid: node._meta.uid,
                  title,
                  description: excerpt,
                  focusArea,
                  image: node.thumbnail ? node.thumbnail.url : undefined,
                  link: link ? link.url : '',
                  linkLabel,
                  linkLogo,
                  createdAt: node._meta.firstPublicationDate,
                }
              }
            )

            const logos = trendsAllFiles.edges.reduce((all, edge) => {
              return {
                ...all,
                [edge.node.name]: edge.node.childImageSharp.fixed.src,
              }
            }, {})

            return (
              <Layout>
                <SEO title="Home" keywords={[`longevity`]} />
                <HomeHeroSlider slides={slides} />

                <div className="flex items-center justify-center">
                  <ScrollLink
                    to="home-focus-areas"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="cursor-pointer mt-1 lg:mt-4"
                  >
                    <img
                      src={arrow}
                      alt=""
                      className="block"
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  </ScrollLink>
                </div>

                {/* Network start */}
                <div className="mx-auto mt-32 mb-24 lg:pb-16 !!!lg:home-network container">
                  <h1 className="font-serif text-2xl lg:text-headline text-blue-dianne font-normal m-8 lg:ml-0 lg:-mb-20 relative z-10">
                    {homepageData.edges[0].node.network_header}
                  </h1>
                  <div className="lg:pt-16 lg:pt-0">
                    <div className="network-outer">
                      <div className="network-inner bg-cover lg:flex items-end">
                        <Img
                          fluid={queryData.networkImage.childImageSharp.fluid}
                          style={{ position: 'absolute' }}
                          className="pin-l pin-r pin-t hidden lg:block"
                        />
                        <div
                          className="bg-selago lg:ml-8 z-10 lg:relative lg:w-1/3 xl:w-1/4 lg:-mb-32"
                          style={{ backgroundColor: '#235466' }}
                        >
                          <Link
                            to="/longevity-network"
                            className="block no-underline lg:p-8"
                          >
                            <ul className="list-reset">
                              {homepageData.edges[0].node.network_links.map(
                                (link, index) => (
                                  <li
                                    key={index}
                                    className={classnames(
                                      'py-8 px-8 lg:px-0 flex items-center justify-between',
                                      {
                                        'border-b-8 lg:border-b border-white lg:border-dusty-gray':
                                          index <
                                          homepageData.edges[0].node
                                            .network_links.length -
                                            1,
                                      }
                                    )}
                                  >
                                    <p className="no-underline text-white uppercase font-sans m-0">
                                      {link.link_label.split(' ')[0]} <br />
                                      {link.link_label.split(' ').slice(1)}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-2/3 lg:ml-auto mt-4 lg:pl-16 xl:pl-0">
                      <p className="leading-normal font-sans text-blue-dianne px-8 lg:p-0 mt-8 lg:mt-0">
                        {homepageData.edges[0].node.network_description}
                      </p>
                      <Link
                        to="/longevity-network"
                        className="flex-no-shrink inline-block mx-8 lg:mx-0 mt-8 font-sans text-blue-dianne text-sm uppercase no-underline font-bold"
                      >
                        {homepageData.edges[0].node.network_cta_label}
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Network end */}

                <div className="max-w-5xl mx-auto" id="home-focus-areas">
                  <HomeFocusAreas
                    column1={column1}
                    column2={column2}
                    column3={column3}
                    isHomepage
                  />
                </div>

                {/* -------------------------------- */}

                <div
                  className="hidden lg:block absolute overflow-hidden -mt-24 pin-l pin-r"
                  style={{ zIndex: -1 }}
                >
                  <img
                    src={dotsTop}
                    alt=""
                    className="block"
                    style={{
                      width: '120%',
                      transform: 'translateX(-10%)',
                      maxWidth: 'none',
                    }}
                  />
                </div>

                <div className="container mx-auto mt-32 mb-16 lg:mb-32 pt-16 text-blue-dianne">
                  <h2 className="font-normal font-serif text-center text-2xl mx-8 lg:mx-0">
                    Latest Insights
                  </h2>
                  <ArticlesColumn articles={latestArticles} grayscale />
                  <div className="flex mx-8 lg:mx-auto mt-16 justify-center">
                    <Link
                      to="/insights"
                      className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
                    >
                      <span className="cta-ie11-fix">Show all Insights</span>{' '}
                      <img src={arrow} alt="" className="ml-8 h-6" />
                    </Link>
                  </div>
                </div>

                {/* -------------------------------- */}

                <div className="container mx-auto my-24 lg:my-32 font-serif text-blue-dianne">
                  <h2 className="font-normal font-serif text-center text-2xl mx-8 lg:mx-0">
                    Latest Trends
                  </h2>
                  <TrendsColumn trends={latestTrends} logos={logos} />
                  <div className="flex mx-8 lg:mx-auto mt-16 justify-center">
                    <Link
                      to="/trends"
                      className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
                    >
                      <span className="cta-ie11-fix">Show all Trends</span>{' '}
                      <img src={arrow} alt="" className="ml-8 h-6" />
                    </Link>
                  </div>
                </div>

                {/* -------------------------------- */}

                <div className="container mx-auto my-24 lg:my-32 text-blue-dianne">
                  <h2 className="font-normal font-serif text-center text-2xl mb-16">
                    Longevity Explorer
                  </h2>
                  <div className="lg:flex">
                    {EXPLORER_ARTICLES.map(({ title, image, link }, index) => (
                      <div
                        key={index}
                        className={classnames(
                          'mb-2 lg:mb-0 lg:w-1/3 flex-shrink-0 flex flex-row-reverse items-center justify-end bg-selago lg:bg-transparent lg:block px-8 lg:px-0 py-4 lg:py-0',
                          {
                            'lg:mr-12': index !== EXPLORER_ARTICLES.length - 1,
                          }
                        )}
                      >
                        <Link
                          to={link}
                          className="block no-underline w-16 lg:w-auto flex-no-shrink ml-auto lg:ml-0"
                        >
                          <Img fluid={queryData[image].childImageSharp.fluid} />
                        </Link>
                        <h2 className="font-serif font-normal lg:mt-10 lg:mx-0">
                          <Link
                            to={link}
                            className="no-underline text-blue-dianne text-lg lg:text-xl"
                          >
                            {title.split('\n')[0]}
                            <br />
                            {title.split('\n')[1]}
                          </Link>
                        </h2>
                        <p className="mt-8 font-sans text-gothic text-sm mx-8 lg:mx-0 hidden lg:block">
                          <Link
                            to={link}
                            className="no-underline hover:underline text-gothic uppercase"
                          >
                            Read more
                          </Link>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                {/* -------------------------------- */}

                <div className="max-w-5xl mx-auto">
                  <HomePodcasts
                    podcasts={latestPodcasts}
                    allLink="/podcasts"
                    showAllLink
                  />
                </div>

                {/* -------------------------------- */}

                <div className="hidden lg:grid container mx-auto initiatives-container my-32">
                  <div className="initiatives-header flex items-center">
                    <h1 className="font-serif text-headline text-blue-dianne font-normal m-8 ml-0">
                      {homepageData.edges[0].node.initiatives_header}
                    </h1>
                  </div>
                  <div className="initiatives-photo1 lg:flex lg:items-end">
                    <Img
                      fixed={queryData.initiatives1Image.childImageSharp.fixed}
                    />
                  </div>
                  <div className="initiatives-photo3">
                    <Img
                      fixed={queryData.initiatives2Image.childImageSharp.fixed}
                    />
                  </div>
                  <div className="initiatives-photo4">
                    <Img
                      fixed={queryData.initiatives3Image.childImageSharp.fixed}
                    />
                  </div>
                  <div className="initiatives-description flex items-center">
                    <p className="font-sans text-blue-dianne mr-8 leading-normal">
                      {RichText.asText(
                        homepageData.edges[0].node.initiatives_description
                      )}
                    </p>
                  </div>
                  <div className="initiatives-cta">
                    <div className="flex justify-end">
                      <Link
                        to="/coming-soon"
                        className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 mt-8 inline-flex items-center no-underline block"
                      >
                        <span className="cta-ie11-fix">
                          {homepageData.edges[0].node.initiatives_cta_label}
                        </span>{' '}
                        <img src={arrow} alt="" className="ml-8 h-6" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="lg:hidden container mx-auto mt-16 mb-16 lg:mb-32 text-blue-dianne">
                  <h2 className="font-normal font-serif text-2xl mb-16 mx-8 lg:mx-0">
                    {homepageData.edges[0].node.initiatives_header}
                  </h2>
                  <div className="lg:flex items-start mb-16">
                    <div className="lg:w-1/3 lg:mr-12">
                      <Img
                        fluid={
                          queryData.initiativesMobileImage.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div className="lg:w-2/3 lg:mr-12  mx-8 lg:mx-0">
                      <p className="leading-normal mt-4 lg:mt-0 lg:mx-0 font-sans measure">
                        {homepageData.edges[0].node.network_description}
                      </p>
                      <p className="mt-8 font-sans text-gothic text-sm">
                        <Link
                          to="/longevity-network"
                          className="no-underline hover:underline text-gothic uppercase"
                        >
                          {homepageData.edges[0].node.network_cta_label}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>

                
              </Layout>
            )
          },
          query
        )}
      />
    )
  }
}

export default IndexPage
